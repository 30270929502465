import { getPage } from '../data-interface/sanity'
import { HOME_SLUG } from 'data/routes'
import Sections from 'components/Sections/Sections'

export default function IndexPage({ data }) {
  return <Sections modules={data.modules} />
}

export async function getStaticProps() {
  const data = await getPage(HOME_SLUG, 'page')

  return {
    props: {
      data,
    },
    revalidate: parseInt(process.env.REVALIDATE),
  }
}
